import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

const ContentPage = ({ data, location, ...props }) => {
    return (
        <SeoPage
            leadImage={data.img0.childImageSharp.fluid.src}
            location={location}
            title="Avocat pour Conduite avec facultés affaiblies par la drogue ou l'alcool"
            description="Si vous êtes interceptés au volant d’un véhicule avec des facultés affaiblies, il est fort probable qu’un avocat puisse vous venir en aide. Avocats Alcool au Volant vous permet de trouver le professionnel qu’il vous faut gratuitement!"
            fr={undefined}
            lawType="criminal"
            specialtyText="spécialisé en alcool au volant">
            <p>
                <strong>
                    Nous entendons souvent parler de l’infraction de conduite en
                    état d’ébriété.{' '}
                </strong>
            </p>
            <p>
                Dans une telle situation, l’agent qui a des motifs raisonnables
                de soupçonner que vous êtes au-dessus de la limite de 80 mg
                d’alcool par 100 ml de sang pourra vous faire passer un test
                d’alcoolémie.
            </p>
            <p>
                <Img
                    fluid={data.img0.childImageSharp.fluid}
                    title="Qu’est-ce que l’infraction de conduite avec les facultés affaiblies?"
                    alt=""
                />
            </p>
            <p>
                Cependant, si vous vous retrouvez en dessous de la limite,
                êtes-vous entièrement hors de danger? Pas tout à fait! Dans une
                telle situation, vous pourriez tout de même être reconnu
                coupable de{' '}
                <strong>conduite avec les facultés affaiblies.</strong>
            </p>
            <p>
                En effet, qu’il soit question d’alcool ou de drogue, vous pouvez
                tout de même ne pas être en mesure de conduire un véhicule, et
                ce, <strong>peu importe quel est le taux d’alcoolémie</strong>{' '}
                dans votre sang au moment de l’interception par la police.
            </p>
            <p>
                Ainsi, si vous êtes accusé d’avoir conduit avec les facultés
                affaiblies, il est important de faire appel à un avocat afin
                d’éviter{' '}
                <Link to="https://avocatsalcoolauvolant.ca/consequences-alcool-volant/">
                    toutes les conséquences
                </Link>{' '}
                qui pourraient s’ensuivre si vous êtes reconnu coupable.
            </p>
            <p>
                <strong>
                    Avocats Alcool au Volant vous permet de savoir comment un
                    avocat peut vous aider en cas d’accusation de conduite avec
                    facultés affaiblies.{' '}
                </strong>
            </p>
            <h2>
                Quelles sont les conséquences de la conduite avec les facultés
                affaiblies?
            </h2>
            <p>
                En vertu du <em>Code criminel</em>, il est interdit de conduire
                avec les facultés affaiblies, et ce,{' '}
                <strong>peu importe à quel degré vous êtes</strong>. Cela
                concerne l’alcool, mais également la drogue et une combinaison
                des deux.{' '}
            </p>
            <p>
                En fonction de la <strong>gravité de l’infraction</strong> que
                vous avez commise, il est possible que la peine varie entre la
                contravention minimale et l’emprisonnement à perpétuité.
                Puisqu’il s’agit d’un crime grave et qu’une telle situation
                constitue une menace importante pour la sécurité publique, les
                sanctions sont appropriées.
            </p>
            <p>
                Pour déterminer les conséquences, il faut absolument prendre en
                considération certains facteurs. Par exemple, si vous en êtes à
                votre <strong>première infraction</strong>, la sanction risque
                d’être moins importante. Toutefois, si vous êtes en récidive,
                les sanctions risquent d'être plus sévères.{' '}
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Infraction</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Peine minimale</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Peine maximale</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Première</p>
                    </td>
                    <td>
                        <p>Amende de 1 000$</p>
                    </td>
                    <td>
                        <p>10 ans d’emprisonnement</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Deuxième</p>
                    </td>
                    <td>
                        <p>30 jours d’emprisonnement</p>
                    </td>
                    <td>
                        <p>10 ans d’emprisonnement</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Troisième</p>
                    </td>
                    <td>
                        <p>120 jours d’emprisonnement</p>
                    </td>
                    <td>
                        <p>10 ans d’emprisonnement</p>
                    </td>
                </tr>
            </table>
            <p>
                Cependant, ces conséquences sont celles de base. En effet, il
                peut exister des situations où votre sanction va diminuer ou
                augmenter. Par exemple, dans le cas d’une{' '}
                <strong>déclaration sommaire de culpabilité</strong>, l’amende
                maximale sera de 1 000$.
            </p>
            <p>
                De plus, si votre conduite avec facultés affaiblies a causé des{' '}
                <strong>lésions corporelles</strong>, vous pourriez, en cas de
                mise en accusation, avoir une peine d’emprisonnement d’une durée
                maximale de 14 ans. Si vous avez causé la mort, la peine
                pourrait alors être à perpétuité.
            </p>
            <h2>Qu’en est-il des taux de concentration interdits?</h2>
            <p>
                Outre l’infraction de conduite avec les facultés affaiblies,
                vous pouvez, dans certains cas, faire face à des infractions
                distinctes lorsque vous avez{' '}
                <strong>une concentration interdite</strong> d’alcool, de
                cannabis ou d’une autre substance prohibée dans votre sang.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img1.childImageSharp.fluid}
                    title="Quel est le taux d’alcoolémie interdit au Québec?"
                    alt=""
                />
            </p>
            <p>
                Cette substance doit être identifiée dans les deux heures qui
                suivent le moment où vous avez pris le volant. Dans ce cas, vous
                pourrez également vous voir imposer une contravention ou, dans
                les circonstances les plus extrêmes, une peine d’emprisonnement
                à perpétuité.{' '}
            </p>
            <p>
                En ce qui concerne le <strong>taux d’alcoolémie</strong>, vous
                avez l’interdiction de conduire si votre taux est d’au moins 80
                mg d’alcool par 100 ml de sang. Si vous êtes intercepté avec un
                taux de 160 mg d’alcool par 100 ml de sang (soit le double), les
                sanctions seront particulièrement graves.{' '}
            </p>
            <p>
                De plus, si vous avez consommé du cannabis, vous ne pouvez pas
                prendre le volant si votre niveau de THC est la principale
                composante de cette substance. En fonction de la concentration,
                votre infraction pourrait être plus ou moins grave.
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Infraction </strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Concentration de THC</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Moins grave</p>
                    </td>
                    <td>
                        <p>Entre 2 ng et 5 ng de THC par ml de sang</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Plus grave</p>
                    </td>
                    <td>
                        <p>Au moins 5 ng de THC par ml de sang</p>
                    </td>
                </tr>
            </table>
            <p>
                Il est également possible que vous ayez consommé de l’alcool et
                du cannabis avant de prendre le volant. Évidemment, lorsque vous
                faites <strong>une combinaison</strong> des deux substances, il
                existe également une concentration interdite. Au Québec, ce taux
                est d’au moins 50 mg d’alcool par 100 ml de sang et au moins 2,5
                g de THC par ml de sang.
            </p>
            <p>
                Finalement, en ce qui concerne{' '}
                <strong>les autres drogues, </strong>il est complètement
                interdit d’avoir une quantité détectable dans votre sans. Cela
                est particulièrement vrai dans les deux heures qui suivent la
                conduite.
            </p>
            <p>
                Toutefois, il est important de savoir qu’il est possible que
                vous ayez un taux de GHB inférieur à{' '}
                <strong>5 mg par litre de sang</strong>. Cela est dû au fait que
                votre corps peut produire des petites concentrations de cette
                drogue naturellement.{' '}
            </p>
            <h2>
                Conduite avec les facultés affaiblies – Quelles sont les
                sanctions administratives?
            </h2>
            <p>
                Lorsque vous êtes intercepté au volant d’un véhicule avec les
                facultés affaiblies, les sanctions criminelles ne sont pas les
                seules conséquences auxquelles vous ferez face. En effet, il
                existe également plusieurs{' '}
                <strong>sanctions administratives</strong>.{' '}
            </p>
            <p>
                Pour déterminer les conséquences qui s’appliquent à vous en
                fonction du <em>Code de la sécurité routière</em>, il est
                important de déterminer si vous en êtes à votre première
                arrestation ou si vous êtes en récidive.{' '}
            </p>
            <h3>La première infraction</h3>
            <p>
                Lorsque vous en êtes à votre{' '}
                <strong>première infraction </strong>de conduite avec facultés
                affaiblies, votre permis de conduire sera, dès votre
                arrestation, suspendu pour une période entre 24 heures et 90
                jours. De plus, selon votre situation, votre véhicule pourrait
                être saisi immédiatement, et ce, pour 30 jours.
            </p>
            <p>
                <Img
                    fluid={data.img2.childImageSharp.fluid}
                    title="Quelles sont les sanctions administratives de la conduite avec facultés affaiblies?"
                    alt=""
                />
            </p>
            <p>
                Dès que vous serez <strong>reconnu coupable </strong>d’avoir
                commis une telle infraction, vous aurez l’interdiction de
                conduire pour au moins 1 an. Votre permis sera également révoqué
                pour une durée qui est généralement entre 1 et 3 ans.{' '}
            </p>
            <p>
                Dans certains cas, il est possible que le juge vous oblige à
                respecter l’une des obligations suivantes:
            </p>
            <ul>
                <li>Programme Alcofrein,</li>
                <li>
                    Programme d’évaluation et de réduction du risque de conduite
                    avec les capacités affaiblies, et{' '}
                </li>
                <li>Antidémarreur. </li>
            </ul>
            <p>
                Finalement, pour la première infraction, vous devrez payer une
                contravention <strong>d’au moins 1 750$</strong>. À ce montant,
                vous devrez ajouter tous les autres frais variables, dont les
                frais d’avocats, l’augmentation de votre prime d’assurance
                automobile et votre contribution au régime d’indemnisation des
                victimes d’actes criminels.
            </p>
            <h3>La deuxième infraction</h3>
            <p>
                Si vous êtes intercepté pour <strong>une deuxième fois</strong>{' '}
                au volant d’un véhicule avec des facultés affaiblies dans les 10
                ans qui suivent votre première infraction, d’autres sanctions
                administratives devront être prises en considération.{' '}
            </p>
            <p>
                <strong>Dès votre arrestation, </strong>votre véhicule sera
                saisi pour une durée de 90 jours et votre permis sera
                automatiquement suspendu pour une durée semblable. Dans certains
                cas, le policier pourrait vous imposer un Programme d’évaluation
                et de réduction du risque de conduite avec les capacités
                affaiblies.{' '}
            </p>
            <p>
                Ensuite, après la <strong>déclaration de culpabilité</strong>,
                vous aurez l’interdiction de conduire votre véhicule pour au
                moins 2 ans. De plus, votre permis sera révoqué pour une durée
                qui va varier entre 3 et 5 ans. Cela vient également avec une
                interdiction d’immatriculer ou d’acquérir un véhicule en votre
                nom.
            </p>
            <h3>La troisième infraction</h3>
            <p>
                Finalement, si vous en êtes à votre{' '}
                <strong>deuxième récidive en 10 ans</strong>, les conséquences
                administratives restent similaires à celles de votre première
                récidive. Toutefois, certaines sanctions seront obligatoires et
                non pas facultatives.
            </p>
            <p>
                <Img
                    fluid={data.img3.childImageSharp.fluid}
                    title="Quelles sont les conséquences d’une récidive de conduite avec facultés affaiblies?"
                    alt=""
                />
            </p>
            <p>
                En effet, cela sera notamment le cas de l’antidémarreur à vie et
                de l’interdiction d’immatriculer, d’acquérir, de louer et de
                mettre en circulation un véhicule en utilisant votre nom.{' '}
            </p>
            <p>
                <strong>
                    Vous souhaitez connaître les sanctions administratives
                    auxquelles vous faites face si vous avez conduit avec les
                    facultés affaiblies?{' '}
                </strong>
            </p>
            <p>
                <strong>
                    Avocats Alcool au Volant vous permet de trouver le
                    professionnel qu’il vous faut en quelques clics seulement.{' '}
                </strong>
            </p>
            <h2>Conduite avec facultés affaiblies – À quoi sert l’avocat?</h2>
            <p>
                Vous avez récemment été intercepté au volant d’un véhicule avec
                des facultés affaiblies? Dans ce cas, il est fort probable qu’un{' '}
                <Link to="https://avocatsalcoolauvolant.ca/avocat-criminel-alcool-volant/">
                    <strong>avocat spécialisé en droit criminel</strong>{' '}
                </Link>
                vous soit d’une grande utilité.
            </p>
            <p>
                Comme vous le constatez, il est important d’avoir un
                professionnel qui connaît non seulement le Code criminel, mais
                également les dispositions du{' '}
                <em>Code de la sécurité routière</em>. Il peut être difficile
                d’évoquer un{' '}
                <Link to="https://avocatsalcoolauvolant.ca/defenses-alcool-volant/">
                    moyen de défense
                </Link>{' '}
                pour contrer une telle infraction.
            </p>
            <p>
                Cependant, grâce à un avocat, vous pourrez vous assurer que les
                procédures entourant votre arrestation ont respecté vos droits.
                Cela sera également le cas en ce qui concerne l’arrestation par
                les policiers.{' '}
            </p>
            <p>
                Ultimement, selon la complexité de votre dossier et l’ensemble
                des preuves retenues contre vous, il est possible que l’avocat
                vous permette d’obtenir un acquittement. Ainsi, vous pourrez
                vous en sortir sans conséquence.
            </p>
            <p>
                <strong>
                    Heureusement, il est désormais possible de trouver l’avocat
                    qu’il vous faut en remplissant le formulaire en ligne
                    d’Avocats Alcool au Volant!
                </strong>
            </p>
        </SeoPage>
    )
}

export default ContentPage

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "avocat-conduite-facultes-affaiblies/conduite-facultes-affaiblies.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "avocat-conduite-facultes-affaiblies/taux-alcoolemie-interdit.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "avocat-conduite-facultes-affaiblies/consequence-sanction-administrative.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "avocat-conduite-facultes-affaiblies/consequences-recidive-facultes-affaiblies.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
